import { SubPageLayout } from '../shared/subpage-layout';
import { LandingPageHeader } from './components/header/landing-page-header';
import { Outlet } from 'react-router-dom';

export const SectionLayout: React.FC = (_props) => {
	return (
		<>
			<SubPageLayout
					fixedHeader={false}
					pageHeader={
							<>
								<LandingPageHeader />
							</>
					}
			>
				<Outlet />
			</SubPageLayout>
		</>
	);
};