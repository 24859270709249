// Create a text object resembling the Netherlands flag.
import React from 'react';

interface NetherlandsFlagTextProps {
  text: string
}

export const NetherlandsFlagText: React.FC<NetherlandsFlagTextProps> = (props) => {
  return (
    <span>
      {props.text.split('').map((char, index) => (
        <span
          key={index}
          style={{ color: index % 3 === 0 ? 'red' : 
                          index % 3 === 1 ? 'white' : 
                          'blue',
                   textShadow: index % 3 === 1 ? '1px 1px black' : 'none'}}
        >
          {char}
        </span>
      ))}
    </span>
  )
}

export default NetherlandsFlagText;