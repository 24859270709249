import { AboutContainer } from '../components/about-container';
import { Helmet } from 'react-helmet';

interface AboutProps {}

export const AboutPage: React.FC<AboutProps> = (props) => {
  return (
    <>
      <Helmet>
        <title>About</title>
      </Helmet>
      <AboutContainer />
    </>
  )
}