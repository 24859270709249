import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route } from 'react-router-dom';
import { RoutePaths } from '../../../route-definitions';

import { SectionLayout } from './section-layout';
import { WelcomePage } from './pages/welcome-page';
import { AboutPage } from './pages/about-page';

export const Root: React.FC = (_props) => {
  return (
    <Routes>
      <Route path="" element={<SectionLayout />}>
        <Route path="" element={<WelcomePage />} />
        <Route path={RoutePaths.Home.relativePath} element={<WelcomePage />} />
        <Route path={RoutePaths.About.relativePath} element={<AboutPage />} />
      </Route>
    </Routes>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

