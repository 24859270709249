import { Menu, MenuProps } from 'antd';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from "framer-motion";
import { RoutePaths } from '../../../../../route-definitions';

interface NavigationMenuProps {}
const MenuItems: MenuProps['items'] = [
  {
    key: RoutePaths.Home.relativePath,
    label: <motion.div
    style={{color: 'blue', fontWeight: 'bolder'}}
    animate={{
      rotate: [0, 0, 270, 270, 0]
    }}
    transition={{ duration: 2 }}>
    Home
  </motion.div>,
    children: undefined
  },
  {
    key: RoutePaths.About.relativePath,
    label: <motion.div
      style={{color: 'blue', fontWeight: 'bolder'}}
      animate={{
        rotate: [0, 0, 270, 270, 0]
      }}
      transition={{ duration: 2 }}>
      About
    </motion.div>,
    children: undefined
  }
]

export const NavigationMenu: React.FC<NavigationMenuProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentMenuItem, setCurrentMenuItem] = useState(location.pathname === '/' ? RoutePaths.Home.relativePath : location.pathname);

  const onMenuItemClick: MenuProps['onClick'] = (item) => {
    console.log('click', item);
    navigate(item?.key);
    setCurrentMenuItem(item?.key);
  }
  
  return (
    <Menu
      mode="horizontal"
      style={{ backgroundColor: 'white', textAlign: 'center', borderBottom: 'none'}}
      items={MenuItems}
      onClick={onMenuItemClick}
      selectedKeys={[currentMenuItem]}
    />
  )
}