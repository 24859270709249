import { motion } from "framer-motion";
import { motions } from '../../../styles/motion';
import { MotionDivType } from "../../../constants";

interface ScrollTriggeredDivProps {
  animate?: any,
  children: any,
  initial?: any,
  style?: any,
  transition?: any,
  type?: MotionDivType,
  viewport?: any,
  whileInView?: any
}

export const ScrollTriggeredDiv: React.FC<ScrollTriggeredDivProps> = (props: any) => {
  const motionTypeToReturn = () => {
    switch (props.type) {
      case MotionDivType.PTitle:
        return <motion.div
          initial={motions.pTitle.initial}
          whileInView={motions.pTitle.whileInView}
          viewport={motions.pTitle.viewport}
          transition={motions.pTitle.transition}
        >
          {props.children}
        </motion.div>
      default:
        return <motion.div
          animate={props.animate ?? {}}
          initial={props.initial ?? {}}
          whileInView={props.whileInView ?? {}}
          viewport={props.viewport ?? {}}
          transition={props.transition ?? {}}
          style={props.style ?? {}}
        >
          {props.children}
        </motion.div>
    }
  }

  return (
    <>
      {motionTypeToReturn()}
    </>
  )
}