import { ComponentQueryLoader } from '../../../ui/molecules/component-query-loader';
import { Welcome } from './welcome';

interface WelcomeContainerProps {}

export const WelcomeContainer: React.FC<WelcomeContainerProps> = (props) => {
  // Add this handling when you have a query to fetch data from the server
  // const { data: data, loading: loading, error: error } = useQuery(WelcomeContainerCVTextQuery);
  
  const cvData = {
    personalInformation: {
      name: "Ikeem Wilson",
      title: "Full Stack Developer",
      summary: `I am a tech-savvy and results-oriented professional with extensive experience in designing, 
        developing, and implementing full-stack web applications using a diverse range of technologies and 
        programming languages. My expertise includes maintaining large databases, creating intricate algorithms, 
        and crafting advanced queries. I excel at aligning platform improvements with technical strategies 
        and possess a deep understanding of software concepts, encompassing both user applications and operating
        systems. My skill set extends to successfully contributing to large-scale IT projects and working with teams 
        of software developers to execute, process, and service enhancements. I am a clear and effective communicator, 
        skilled at building enduring professional relationships.`,
    },
    areasOfExpertise: {
      title: 'Areas of Expertise',
      expertise: [
        `Full Stack Development`,  
        `Application Development`, 
        `Frontend & Backend Development`,
        `Strategic Analysis & Execution`,
        `Team Training & Development`,
        `Database Management System`,
        `Regulatory Compliance`,
        `Processes Enhancement`,
        `Project Management`
      ]
    },
    technicalProficiencies: [
      {
        category: "Languages",
        items: [
          "React (Typescript)",
          "C#",
          "HTML",
          "JavaScript",
          "CSS5",
          "PL/SQL",
          "XAML",
        ],
      },
      {
        category: "Cloud",
        items: ["Azure", "AWS"],
      },
      {
        category: "Technologies",
        items: [
          "Node",
          "Github",
          "Ant Design",
          "Framer",
          "WPF",
          "MVC",
        ],
      },
      {
        category: "Software",
        items: [
          "Git",
          "Visual Studio Code",
          "Microsoft Office Suite",
          "Visual Studio (TFS)",
        ],
      },
      {
        category: "Hardware",
        items: [
          "Worked with Atalasoft Twain driver for printers/scanners",
          "Barcoding experience.",
        ],
      }
    ],
    careerExperience: [
      {
        employer: "ECFMG",
        location: {
          city: "Philadelphia",
          state: "Pennsylvania",
        },
        title: "Full Stack Developer III",
        duration: {
          start: {
            month: "November",
            year: "2011",
          },
          end: null,
        },
        summary: `Implement azure active directory B2C tenants and custom policies to allow seamless integration of users, groups, and credentials
          of employees and customers. Design, develop, and deploy features swiftly by using Graph QL and Oracle. Coordinate team of eight
          employees as well as mentor interns and new hires to stream line flow of organizational operations. Orchestrate in depth
          documentation for app infrastructure to reduce cost of system upgrades, new hardware, and software.`,
        accomplishments: [
          `Enhanced robust support for browsers and server-side-rendering by developing web applications using react, typescript,
          and ant design.`,
          `Maximized applications efficiency, operability, and flexibility, as well as reduced technical debt by converting classic .NET
          apps to C# and React applications.`,
          `Ensured long-term stability and availability of services by collaborating with e-connect for great initiatives.`,
          `Secured company revenue and earn millions yearly by developing and optimizing post-COVID application.`,
          `Established payment architectures using pay pal and cyber source to exchange payment instructions, initiate settlement
          and perform additional functions.`
        ]
      },
      {
        employer: `Explore Information Services`,
        location: {
          city: `Marshall`,
          state: `Minnesota`,
        },
        title: `IT Intern`,
        duration: {
          start: {
            month: `Februrary`,
            year: `2010`,
          },
          end: {
            month: `April`,
            year: `2011`,
          },
        },
        summary: `Enhanced data integrity and managed dynamically generated documents by executing Middle-tier SQL programming.
          Collaborated with team of four senior developers to develop full-stack applications across various platforms using latest industry
          adopted technology and framework. Ensured compliance with government ADA standards by daily revising / updating web pages.`,
        accomplishments: [
          `Redesigned and optimized previously created websites by debugging C# / HTML code.`
        ],
      },
      {
        employer: `The Schwan Food Company`,
        location: {
          city: `Marshall`,
          state: `Minnesota`,
        },
        title: `Software Quality Assurance Test Engineer`,
        duration: {
          start: {
            month: `May`,
            year: `2009`,
          },
          end: {
            month: `August`,
            year: `2009`,
          },
        },
        summary: null,
        accomplishments: [
          `Improved logistic processes which saved money for the company`,
          `Nominated for employee of the month twice in my short time there`,
          `Created rules for the testing of new website features`,
          `Quickly accomplished tasks given to me`
        ],
      },
      {
        employer: `Target`,
        location: {
          city: `Brooklyn Center`,
          state: `Minnesota`,
        },
        title: `Guest Service Team Member`,
        duration: {
          start: {
            month: `July`,
            year: `2005`,
          },
          end: {
            month: `April`,
            year: `2009`,
          },
        },
        summary: null,
        accomplishments: [
          `Worked in multiple areas of the store including electronics`,
          `Informed consumers on technological equipment`,
          `Assessed consumer needs and recommended products accordingly`,
          `Trained new hires on store policies and procedures`
        ],
      }
    ],
    education: [
      {
        school: "Southwest Minnesota State University",
        location: {
          city: "Marshall",
          state: "Minnesota",
          country: "USA",
        },
        degree: "Bachelor of Science",
        major: "Computer Science",
        duration: {
          start: {
            month: "August",
            year: "2006",
          },
          end: {
            month: "May",
            year: "2011",
          }
        }
      }
    ]
  }

  return (
    <ComponentQueryLoader 
      hasData={true}
      hasDataComponent={<Welcome cvData={cvData}/>}
      noDataComponent={<>No component</>}
    />
  )
}