import { WelcomeContainer } from '../components/welcome-container';
import { Helmet } from 'react-helmet';

interface WelcomeProps {}

export const WelcomePage: React.FC<WelcomeProps> = (props) => {
  return (
    <>
      <Helmet>
        <title>Welcome</title>
      </Helmet>
      <WelcomeContainer />
    </>
  )
}