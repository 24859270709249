import { ComponentQueryLoader } from '../../../ui/molecules/component-query-loader';
import { About } from './about';

interface AboutContainerProps {}

export const AboutContainer: React.FC<AboutContainerProps> = (props) => {
  // Add this handling when you have a query to fetch data from the server
  // const { data: data, loading: loading, error: error } = useQuery(WelcomeContainerFeeAmountDocument);
  
  return (
    <ComponentQueryLoader 
      hasData={true}
      hasDataComponent={<About />}
      noDataComponent={<>No component</>}
    />
  )
}