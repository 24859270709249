import { Grid, Layout } from 'antd';

const { useBreakpoint } = Grid;
const { Content } = Layout;

interface SubPageLayoutProps {
  fixedHeader?: boolean;
  pageHeader?: JSX.Element;
  children?: JSX.Element;
}

export const SubPageLayout: React.FC<SubPageLayoutProps> = (props) => {
  const screens = useBreakpoint();
  return (
    <>
      {props.pageHeader && <>{props.pageHeader}</>}
      {props.children}
    </>
  )
}