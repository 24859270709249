import { useState } from 'react';
import { Tooltip } from 'antd';
import { motion } from 'framer-motion';
import { tooltipColors, welcomePageIntroTimes } from '../../../constants';


const keyWordList = [
  {
    text: 'Azure',
    description: <span>All photos on this website are hosted in <b>Azure Blob Storage</b>.</span>
  },
  {
    text: 'AWS',
    description: <span><b>AWS Amplify</b> was used to host this website.</span>
  },
  {
    text: 'Full Stack Development',
    description: <span>This is my current job title. My day to day includes interactions with <b>React</b>, <b>GraphQL</b>, and <b>Azure</b></span>,
    appearsFirst: true
  }
]

interface HoverKeyWordProps {
  text: string,
  withComma?: boolean
}

export const HoverKeyWord: React.FC<HoverKeyWordProps> = (props) => {
  const [commaText] = useState(props.withComma ? ', ' : '');

  const boxShadowCountdownList = () => {
    // count down from 100 to 0
    const list = [];
    for (let i = 100; i >= 0; i--) {
      list.push(`0 0 0 ${i}vmax rgba(0,0,0,.7)`);
    }

    return list;
  }

  //check if the props.text is in the keyWordList
  if (keyWordList.find(keyWord => keyWord.text.replace(/\s/g, "").toUpperCase() === props.text.replace(/\s/g, "").toUpperCase())) {
    const randomColor = tooltipColors[Math.floor(Math.random() * tooltipColors.length)]; 
    //if it is, return the text with a hover effect
    if (keyWordList.find(keyWord => keyWord.text.replace(/\s/g, "").toUpperCase() === props.text.replace(/\s/g, "").toUpperCase())?.appearsFirst === true) {
      //Only give background effect to the first instance of a keyword
      return (
        <Tooltip 
          title={keyWordList.find(keyWord => keyWord.text.replace(/\s/g, "").toUpperCase() === props.text.replace(/\s/g, "").toUpperCase())?.description}
          color={randomColor}
        >
          <motion.span
            style={{
              color: randomColor, 
              fontWeight: 625, 
              display:'inline-block',
              textShadow: '0.25px 0.25px black'
            }}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
          >
            <motion.span 
              initial={{
                boxShadow: '0 0',
                borderRadius: '25px'
              }}
              animate={{
                boxShadow: [
                  '0 0 0 0vmax rgba(0,0,0,.3)',
                  ...boxShadowCountdownList()],
                pointerEvents: ['none'],
                backgroundColor: ['white', 'transparent'],
                padding: ['0px 0px', '20px 20px', '20px 20px', '20px 20px', '0px 0px']
              }}
              transition={{ duration: welcomePageIntroTimes.durationInSec, delay: welcomePageIntroTimes.delayInSec }}
            >
              {props.text}
            </motion.span>
          </motion.span>
          <span>
            {commaText}
          </span>
        </Tooltip>
      )
    }
    else {
      return (
        <Tooltip 
          title={keyWordList.find(keyWord => keyWord.text.replace(/\s/g, "").toUpperCase() === props.text.replace(/\s/g, "").toUpperCase())?.description}
          color={randomColor}
        >
          <motion.span 
            style={{
              color: randomColor, 
              fontWeight: 625, 
              display:'inline-block',
              textShadow: '0.25px 0.25px black'
            }}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.8 }}
          >
            {props.text}
          </motion.span>
          <span>
            {commaText}
          </span>
        </Tooltip>
      )
    }
  }
  else {
    //if it is not, return the text without a hover effect
    return (
        <span>{props.text + commaText}</span>
    )
  }
}

export default HoverKeyWord;