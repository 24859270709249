interface ComponentQueryLoaderProps {
  error?: any;
  loading?: boolean;
  hasData?: boolean;
  hasDataComponent: JSX.Element;
  noDataComponent: JSX.Element;
}

export const ComponentQueryLoader: React.FC<ComponentQueryLoaderProps> = (props) => {
  if (props.error) {
    return <> </>
  }
  if (props.loading) {
    return <> </>
  }
  if (props.hasData) {
    return props.hasDataComponent;
  }
  return props.noDataComponent;
}