// framer motion components types
export enum MotionDivType {
  P,
  PTitle
}

// other constantss
export const tooltipColors = [
  'pink',
  'orange',
  'green',
  'blue',
  'purple',
  // 'geekblue',
  'magenta',
  'volcano',
  'gold'
];

// used to control the duration and delay of the welcome page grey out when a user first visits the site
export const welcomePageIntroTimes = {
  durationInSec: 3,
  delayInSec: 2.25
}

// used to control how long the special effects on the welcome page should pause
export const pauseWelcomePageEffectsDurationInMinutes = 30;