export const RoutePaths = {
  Root: {
    fullPath: '*',
  },
  Home: {
    relativePath: '/home'
  },
  About: {
    relativePath: '/about'
  }
}